"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";

const navItems = [
  { label: "Home", href: "/" },
  { label: "Services", href: "/services" },
  { label: "About Us", href: "/about_us" },
  { label: "Contact Us", href: "/contact_us" },
  { label: "FAQ", href: "#home_faq" },
  { label: "Blog", href: "#blogs" },
];

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const pathName = usePathname();
  const router = useRouter();

  const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

  // Handle menu item click
  const handleMenuItemClick = (event, item) => {
    if (item.href.startsWith("#")) {
      // Prevent default action
      event.preventDefault();

      // Store the target section in local storage
      localStorage.setItem("targetSection", item.href);

      // Check if currently on home page
      if (pathName === "/") {
        const targetElement = document.querySelector(item.href);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          localStorage.removeItem("targetSection");
        }
      } else {
        // Navigate to home
        router.push("/");
      }
    }
    setMobileMenuOpen(false);
  };

  //  to scroll to target section when pathname changes
  useEffect(() => {
    const targetSection = localStorage.getItem("targetSection");

    if (targetSection) {
      const targetElement = document?.querySelector(targetSection);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      localStorage.removeItem("targetSection");
    }
  }, [pathName]);

  return (
    <nav className="bg-white border-b">
      <div className="flex items-center justify-between max-w-[120rem] mx-auto p-3">
        <Link
          href="/"
          className="w-44 md:w-40 xl:w-auto active:scale-95 transition-all  duration-300"
        >
          <Image
            src="https://res.cloudinary.com/dydv6uxzo/image/upload/v1730352507/RumaNails/Logo/NavLogo_upscaled_u6zwp4.webp"
            width={250}
            height={200}
            priority={true}
            quality={100}
            className="object-contain w-auto h-auto"
            alt="Logo"
          />
        </Link>

        <div className="flex  items-center md:order-2 space-x-1 md:space-x-2 rtl:space-x-reverse xl:w-40 justify-end">
          <section className="hidden md:flex items-center gap-3 justify-end">
            <div>
              <Link
                href="/services"
                className="btn btn-sm lg:btn-md border-none text-white bg-primaryColor hover:bg-primaryColor"
              >
                Explore Now
              </Link>
            </div>
          </section>

          {/* Mobile menu toggle */}
          <button
            onClick={toggleMobileMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="mega-menu"
            aria-expanded={isMobileMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            {isMobileMenuOpen ? (
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Desktop Menu */}
        <div
          id="desktop-menu"
          className="hidden md:flex items-center justify-between w-full md:w-auto "
        >
          <ul className="flex flex-row font-medium lg:text-lg  lg:space-x-6 rtl:space-x-reverse">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link
                  href={item.href}
                  className={`block py-2 px-3 ${
                    pathName === item.href
                      ? "text-primaryColor font-semibold"
                      : "text-blackCustom font-medium hover:text-primaryColor transition-all"
                  }`}
                  onClick={(event) => handleMenuItemClick(event, item)}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        id="mobile-menu"
        className={`${
          isMobileMenuOpen ? "open" : ""
        } w-full border-t border-dotted`}
      >
        <ul className="flex flex-col font-medium text-lg">
          {navItems.map((item, index) => (
            <li key={index} className="border-b border-dotted">
              <Link
                href={item.href}
                className={`block py-2 px-3 ${
                  pathName === item.href
                    ? "text-primaryColor font-semibold"
                    : "text-blackCustom font-medium hover:text-primaryColor transition-all"
                }`}
                onClick={(event) => handleMenuItemClick(event, item)}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
